// import the accompanying CSS (requires style-loader)
import { Partytown } from '@builder.io/partytown/react';
import { liveProvider } from '@refinedev/appwrite';
import { Refine } from '@refinedev/core';
import routerProvider, {
  UnsavedChangesNotifier,
} from '@refinedev/nextjs-router';
import { authProvider } from 'composeiq/authProvider';
import { dataProvider, teamsProvider } from 'composeiq/data-provider';
import 'composeiq/styles/global.css';
import { appwriteClient } from 'composeiq/utility';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ComponentType, PropsWithChildren, useEffect } from 'react';

import PageLoader from '@components/PageLoader';
import useDarkTheme, {
  DarkThemeProvider,
} from '@components/hooks/useDarkTheme';
import { AppLayout, PublicLayout } from '@components/layouts';
import notificationProvider from 'composeiq/notificationProvider';
import { NextPage } from 'next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initAmplitude } from '../utility/tracker';
import { initConsentManager } from '../utility/consentManager';
import AppTour from '@components/layouts/App/AppTour';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
const AppInitComponent = () => {
  useEffect(() => {
    initAmplitude();
    initConsentManager();
  }, []);
  return null;
};

const Layouts: Record<string, ComponentType<PropsWithChildren>> = {
  app: AppLayout,
  default: PublicLayout,
};

export type PageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  layout?: keyof typeof Layouts;
  layoutProps?: any;
};

type AppPropsWithLayout = AppProps & {
  Component: PageWithLayout;
};

const ToastContainerWrapper = () => {
  const { isDarkTheme } = useDarkTheme();
  return <ToastContainer theme={isDarkTheme ? 'dark' : 'light'} />;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  const { t, i18n } = useTranslation();
  const Layout = Layouts[Component.layout ?? 'default'];
  const layoutProps = Component.layoutProps ?? {};

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <>
      <Refine
        routerProvider={routerProvider}
        dataProvider={{
          default: dataProvider,
          teams: teamsProvider,
        }}
        liveProvider={liveProvider(appwriteClient, {
          databaseId: process.env.NEXT_PUBLIC_APPWRITE_DB ?? '',
        })}
        options={{ liveMode: 'auto', disableTelemetry: true }}
        notificationProvider={notificationProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
      >
        <Head>
          <Partytown debug={true} forward={['dataLayer.push']} />
          <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        </Head>
        <DarkThemeProvider>
          <Layout {...layoutProps}>
            <Component {...pageProps} />
            <UnsavedChangesNotifier />
          </Layout>
          <ToastContainerWrapper />
        </DarkThemeProvider>
      </Refine>
      <PageLoader />

      <AppInitComponent />
    </>
  );
}

export default appWithTranslation(MyApp);
