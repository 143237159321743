import {
  Appwrite,
  getAppwriteFilters,
  getAppwritePagination,
  getAppwriteSorting,
  ID,
  Teams,
} from '@refinedev/appwrite';
import { DataProvider } from '@refinedev/core';

export const teamsProvider = (appwriteClient: Appwrite): DataProvider => {
  const teams = new Teams(appwriteClient);

  return {
    getList: async ({
      hasPagination = true,
      pagination = { current: 1, pageSize: 10 },
      filters,
      sort,
    }) => {
      const appwriteFilters = getAppwriteFilters(filters);
      const appwritePagination = hasPagination
        ? getAppwritePagination(
            pagination?.current ?? 0,
            pagination.pageSize ?? 10
          )
        : [];

      const appwriteSorts = getAppwriteSorting(sort);

      const { total, teams: data } = await teams.list([
        ...appwriteFilters,
        ...appwritePagination,
        ...appwriteSorts,
      ]);

      return {
        data: data.map(({ $id, ...restData }: { $id: string }) => ({
          id: $id,
          ...restData,
        })) as any,
        total,
      };
    },
    getOne: async ({ id }) => {
      const { $id, ...restData } = await teams.get(id.toString());
      const members = await teams.listMemberships(id.toString());
      return {
        data: {
          id: $id,
          ...restData,
          memberships: [...members?.memberships] ?? [],
        },
      } as any;
    },
    update: async ({ id, variables, metaData }) => {
      // const permissions = [
      //   Permission.read(Role.any()),
      //   Permission.write(Role.any()),
      //   ...(metaData?.readPermissions ?? ""),
      //   ...(metaData?.writePermissions ?? ""),
      // ];

      const roles = [...metaData?.roles];

      const { $id, total, ...restData } = await teams.update(
        id.toString(),
        (variables as any).name
      );

      return {
        data: {
          id: $id,
          ...restData,
        },
      } as any;
    },
    create: async ({ variables, metaData }) => {
      // const permissions = [
      //   Permission.read(Role.any()),
      //   Permission.write(Role.any()),
      //   ...(metaData?.readPermissions ?? ""),
      //   ...(metaData?.writePermissions ?? ""),
      // ];

      const roles = [...metaData?.roles];

      const { $id, ...restData } = await teams.create(
        metaData?.documentId ?? ID.unique(),
        (variables as unknown as { name: string }).name,
        roles
      );

      return {
        data: {
          id: $id,
          ...restData,
        },
      } as any;
    },
    getApiUrl: () => {
      throw Error(
        "'getApiUrl' method is not implemented on refine-appwrite data provider."
      );
    },
    deleteOne: async ({ resource, id }) => {
      await teams.delete(id.toString());
      return {
        data: { id },
      } as any;
    },
  };
};
