import {
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

let darkTheme = false;
if (typeof window !== 'undefined') {
  if (window?.localStorage.theme === undefined) {
    darkTheme = document?.documentElement.classList.contains('dark');
  } else {
    darkTheme = window?.localStorage.theme === 'dark';
  }
}

const DarkThemeContext = createContext<{
  isDarkTheme: boolean;
  setDarkTheme: Dispatch<SetStateAction<boolean>>;
}>({
  isDarkTheme: darkTheme,
  setDarkTheme: () => {},
});

function useIsDarkTheme() {
  const [isDarkTheme, setDarkTheme] = useState(darkTheme);
  useEffect(() => {
    if (isDarkTheme) {
      window?.localStorage.setItem('theme', 'dark');
      document?.documentElement.classList.add('dark');
      document?.documentElement.classList.add('c_darkmode');
      document?.documentElement.setAttribute('data-theme', 'dark');
    } else {
      window?.localStorage.removeItem('theme');
      document?.documentElement.classList.remove('dark');
      // For toggling Cookie consent dark mode
      document?.documentElement.classList.remove('c_darkmode');
      document?.documentElement.setAttribute('data-theme', 'custom');
    }
  }, [isDarkTheme]);

  useEffect(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      window?.localStorage.theme === 'dark' ||
      (!('theme' in localStorage) &&
        window?.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      setDarkTheme(true);
    } else {
      setDarkTheme(false);
    }
  }, []);

  return { isDarkTheme, setDarkTheme };
}

export const DarkThemeProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { isDarkTheme, setDarkTheme } = useIsDarkTheme();
  return (
    <DarkThemeContext.Provider value={{ isDarkTheme, setDarkTheme }}>
      {children}
    </DarkThemeContext.Provider>
  );
};

export default function useDarkTheme() {
  return useContext(DarkThemeContext);
}
