import Link from 'next/link';
import clsx from 'clsx';
import { UrlObject } from 'url';
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
  ReactNode,
  forwardRef,
} from 'react';
import Loading from './Loading';
import Tooltip from './Tooltip';

type ButtonVariant = 'solid' | 'outline';
type ColorVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'transparent'
  | 'slate'
  | 'blue'
  | 'white';
type ButtonSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'all';

const baseStyles: Record<ButtonVariant, string> = {
  solid: 'group items-center shadow-md justify-center btn',
  outline: 'group items-center justify-center shadow-md btn btn-outline',
};

const activeStyles: Record<ColorVariant, string> = {
  primary: '!text-white !bg-indigo-500 dark:!bg-indigo-400',
  secondary: '!bg-gray-50 dark:!bg-white/20',

  slate: '!bg-slate-700 !text-slate-100',
  blue: '!text-slate-100 !bg-blue-500',
  white: '!bg-blue-50',
  danger: '',
  transparent: '',
};

const variantStyles: Record<ButtonVariant, Record<ColorVariant, string>> = {
  solid: {
    danger: 'btn-error',
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    transparent: 'btn-ghost shadow-none',

    slate:
      'bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900',
    blue: 'bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600',
    white:
      'bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white',
  },
  outline: {
    danger: 'btn-error',
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    transparent: 'btn-ghost',

    blue: '',

    slate:
      'ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300',
    white:
      'ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white',
  },
};

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  tooltip?: ReactNode;
  href?: UrlObject | string;
  variant?: ButtonVariant;
  color?: ColorVariant;
  size?: ButtonSize;
  rounded?: boolean;
  circle?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  active?: boolean;
}

export const Button = forwardRef(function BtnElem(
  {
    variant = 'solid',
    color = 'secondary',
    size = 'all',
    rounded,
    fullWidth,
    className,
    href,
    loading,
    tooltip,
    children,
    active,
    circle,
    ...props
  }: PropsWithChildren<ButtonProps>,
  forwardedRef
) {
  const classNames = clsx(
    baseStyles[variant],
    variantStyles[variant][color],
    { 'btn-active': active },
    {
      'btn-xs': size === 'sm',
      'btn-sm': size === 'md',
      'btn-md': size === 'lg',
      'btn-lg': size === 'xl',
      'btn-xs lg:btn-sm 3xl:btn-md': size === 'all',
    },
    {
      'rounded-full': rounded && !circle,
      'rounded lg:rounded-md': !rounded && !circle && size === 'all',
      rounded: !rounded && !circle && (size === 'sm' || size === 'md'),
      'rounded-md':
        !rounded &&
        !circle &&
        (size === '2xl' || size === 'lg' || size === 'xl'),
    },
    {
      'inline-flex': !fullWidth,
      'btn-block': fullWidth,
      'btn-circle': circle,
      loading: loading,
    },
    className
  );

  // const loadingEl = loading && (
  //   <Loading
  //     className={clsx({
  //       '!text-gray-400': color === 'secondary',
  //     })}
  //   />
  // );

  const btnEl = href ? (
    <Link
      href={href}
      className={classNames}
      {...(props as any)}
      ref={forwardedRef}
    >
      {children}
    </Link>
  ) : (
    <button className={classNames} {...props} ref={forwardedRef as any}>
      {children}
    </button>
  );

  if (tooltip) {
    return <Tooltip message={tooltip}>{btnEl}</Tooltip>;
  }

  return btnEl;
});
