import Link from 'next/link';
import { Button } from './Button';

export function NavLink({ href, children, className }) {
  return (
    <Button color="transparent" className={className} href={href}>
      {children}
    </Button>
  );
}
