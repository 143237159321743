import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { account } from './appwriteClient';

let timeCreated = 0;
let jwtToken: string = '';

export const getJwtToken = async () => {
  if (Date.now() - timeCreated > 14 * 60 * 1000) {
    try {
      const { jwt } = await account.createJWT();
      jwtToken = jwt;
      timeCreated = Date.now();
    } catch (e) {
      console.error(e);
    }
  }
  return jwtToken;
};

export const resetJwtToken = () => {
  timeCreated = 0;
};

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST,
});

AXIOS_INSTANCE.interceptors.request.use(async (config) => {
  // secure storage
  const token = await getJwtToken();
  // set authorization header
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }) => data
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by react Query');
  };

  return promise;
};

export default customInstance;

export interface ErrorType<Error> extends AxiosError<Error> {}
