import { dataProvider as dpCreator } from '@refinedev/appwrite';
import { appwriteClient } from 'composeiq/utility';
import { teamsProvider as tpCreator } from './teams';

export const dataProvider = dpCreator(appwriteClient, {
  databaseId: process.env.NEXT_PUBLIC_APPWRITE_DB ?? '',
});

export default dataProvider;

export const teamsProvider = tpCreator(appwriteClient);
