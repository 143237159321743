import { Router } from 'next/router';
import { useEffect, useState } from 'react';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const PageLoader = () => {
  useEffect(() => {
    Router.events.on('routeChangeStart', (url) => {
      NProgress.start();
    });
    Router.events.on('routeChangeComplete', (url) => {
      NProgress.done(false);
    });

    Router.events.on('routeChangeError', (url) => {
      NProgress.done(false);
    });
  }, [Router]);

  return null;
};

export default PageLoader;
