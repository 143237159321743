import {
  Account,
  Appwrite,
  Storage,
  Databases,
  Teams,
} from '@refinedev/appwrite';

const APPWRITE_URL = process.env.NEXT_PUBLIC_APPWRITE_HOST ?? '';
const APPWRITE_PROJECT = process.env.NEXT_PUBLIC_APPWRITE_PROJECT ?? '';

const appwriteClient = new Appwrite();

appwriteClient.setEndpoint(APPWRITE_URL).setProject(APPWRITE_PROJECT);
const account = new Account(appwriteClient);
const storage = new Storage(appwriteClient);
const databases = new Databases(appwriteClient);
const teams = new Teams(appwriteClient);

export { appwriteClient, account, storage, databases, teams };
