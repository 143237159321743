import React, { ReactNode, forwardRef } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import clsx from 'clsx';

type TooltipPrimitiveProps = TooltipPrimitive.TooltipContentProps &
  TooltipPrimitive.TooltipProps;
interface TooltipProps extends TooltipPrimitiveProps {
  message: ReactNode;
  className?: string;
}
export default forwardRef<HTMLDivElement, TooltipProps>(function Tooltip(
  {
    children,
    message,
    open,
    defaultOpen,
    onOpenChange,
    delayDuration,
    disableHoverableContent,
    className,
    ...props
  }: TooltipProps,
  forwardedRef
) {
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.TooltipPortal>
        <TooltipPrimitive.Content
          className={clsx(
            'max-w-xs rounded bg-neutral px-2 py-1 text-sm text-neutral-content',
            'opacity-0 transition delay-100 duration-200 ease-in-out',
            'data-[state=delayed-open]:opacity-100 data-[state=delayed-open]:delay-75',
            'data-[state=instant-open]:opacity-100 data-[state=instant-open]:delay-75',
            className
          )}
          side="top"
          align="center"
          {...props}
          ref={forwardedRef}
        >
          {message}
          <TooltipPrimitive.Arrow width={11} height={5} />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.TooltipPortal>
    </TooltipPrimitive.Root>
  );
});
