import * as amplitude from '@amplitude/analytics-browser';
import { setUserid } from './eventTracker';
const isProduction = process.env.NODE_ENV === 'production';
export const initAmplitude = async () => {
  console.log('initializing amplitude ');
  if (isProduction && typeof window !== 'undefined') {
    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE, undefined, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
    });
  }
};

export const setTrackerUser = (userId: string) => {
  if (isProduction && typeof window !== 'undefined') {
    amplitude.setUserId(userId);
    setUserid(userId);
  }
};

export const trackEvent = (
  eventInput: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, any>,
  eventOptions?: amplitude.Types.EventOptions
) => {
  if (isProduction && typeof window !== 'undefined') {
    console.log('tracking event ', eventInput, eventProperties);
    amplitude.track(eventInput, eventProperties, eventOptions);
  }
};
