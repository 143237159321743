import { AppwriteException, ID } from '@refinedev/appwrite';
import { AuthBindings } from '@refinedev/core';

const APP_URL = '/dashboard';
const PHONE_URL = '/verify/phone';
const EMAIL_URL = '/verify/email';
import { account, databases } from './utility';
import { resetJwtToken } from './utility/axios';
import { setTrackerUser, trackEvent } from './utility/tracker';

function getRedirectUrl(emailVerified: boolean, phoneVerified: boolean) {
  // return emailVerified ? phoneVerified ? APP_URL : PHONE_URL : EMAIL_URL
  return emailVerified ? APP_URL : EMAIL_URL;
}
export const authProvider: AuthBindings = {
  login: async ({ email, password, providerName }) => {
    try {
      if (providerName === 'google') {
        await account.createOAuth2Session(
          providerName,
          `${window?.location?.origin}/dashboard`,
          `${window?.location?.origin}/login`
        );
        trackEvent('login event', {
          loginType: 'google',
        });
        return {
          success: true,
        };
      }
      const session = await account.createEmailSession(email, password);
      if (session) {
        const user = await account.get();
        trackEvent('login event', {
          loginType: 'web',
        });
        return {
          success: true,
          redirectTo: getRedirectUrl(
            user.emailVerification,
            user.phoneVerification
          ),
        };
      }
      return {
        success: false,
      };
    } catch (e) {
      const { type, message, code } = e as AppwriteException;
      return {
        success: false,
        error: {
          statusCode: code,
          message,
          name: 'Login failed',
        },
      };
    }
  },
  logout: async () => {
    try {
      await account.deleteSession('current');
      trackEvent('logout event');
      resetJwtToken();
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      redirectTo: '/login',
    };
  },
  onError: async (error) => {
    console.log(error);
    return { error };
  },
  check: async () => {
    try {
      const session = await account.get();

      if (session) {
        setTrackerUser(session.$id);
        return {
          authenticated: true,
        };
      }
      setTrackerUser(undefined);
    } catch (error: any) {
      setTrackerUser(undefined);
      return {
        authenticated: false,
        error: error,
        logout: true,
        redirectTo: '/login',
      };
    }

    return {
      authenticated: false,
      error: new Error('Session not found'),
      logout: true,
      redirectTo: '/login',
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    try {
      const user = await account.get();

      if (user) {
        return user;
      }
    } catch (e) {
      console.log('User not logged in');
    }

    return null;
  },
  forgotPassword: async ({ email }) => {
    // send password reset link to the user's email address here

    try {
      await account.createRecovery(
        email,
        `${window?.location?.origin}/reset-password`
      );
      return {
        success: true,
        redirectTo: '/login',
      };
    } catch (e) {
      console.log('forgot password', e);
    }
    // if request is not successful
    return {
      success: false,
      error: {
        name: 'Forgot Password Error',
        message: 'Email address does not exist',
      },
    };
  },

  updatePassword: async ({
    password,
    confirmPassword,
    userId,
    secret,
  }: {
    userId: string;
    secret: string;
    password: string;
    confirmPassword: string;
  }) => {
    // update the user's password here

    try {
      await account.updateRecovery(userId, secret, password, confirmPassword);
      return {
        success: true,
        redirectTo: '/login',
      };
    } catch (e) {
      console.log('reset password', e);
      return {
        success: false,
        error: {
          name: 'Reset password failed',
          message: (e as AppwriteException).message,
        },
      };
    }
  },
  register: async ({ email, password, name, providerName }) => {
    try {
      if (providerName) {
        await account.createOAuth2Session(
          providerName,
          `${window?.location?.origin}/dashboard`,
          `${window?.location?.origin}/login`
        );
        return;
      }
      const newUserId = ID.unique();
      const user = await account.create(newUserId, email, password, name);

      if (user) {
        // for verification we need to create an email to send the verification email
        await account.createEmailSession(email, password);
        await account.createVerification(
          `${window?.location?.origin}/verify/email`
        );
      }
      return {
        success: true,
        redirectTo: getRedirectUrl(
          user.emailVerification,
          user.phoneVerification
        ),
      };
    } catch (error) {
      return {
        success: false,
        error: error as AppwriteException,
      };
    }
  },
};
