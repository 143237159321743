import { PropsWithChildren, useEffect } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';

interface LayoutProps {
  hideLogin?: boolean;
}

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  children,
  hideLogin,
}) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window['Tawk_API']?.showWidget();
    }
  }, []);
  return (
    <div className="bg-white" data-theme="custom">
      <Header hideLogin={hideLogin} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};
