import { PostHog } from 'posthog-js';

let isPHConfigured = false;

const isProduction = process.env.NODE_ENV === 'production';

const posthogRunner = (cb: (ph: PostHog) => void) => {
  if (isProduction && typeof window !== 'undefined') {
    import('posthog-js').then((ph) => {
      const posthog = ph.default;

      if (!isPHConfigured) {
        posthog.init(process.env.NEXT_PUBLIC_PH_KEY, {
          api_host: 'https://app.posthog.com',
        });
        isPHConfigured = true;
      }

      cb(posthog);
      return ph;
    });
  }
};

export const captureEvent = (
  event_name: string,
  properties?: Record<string, any>,
  options?: Record<string, any>
): void => {
  posthogRunner((ph) => {
    ph.capture(event_name, properties, options);
  });
};

export const setUserid = (userId: string, options?: Object) => {
  posthogRunner((ph) => {
    ph.identify(userId, options);
  });
};
